const ContactInfo = () => {
  const contactContent = [
    // {
    //   id: 1,
    //   title: "Memorial Le Joola",
    //   action: "tel:+221 77 000 00 00",
    //   text: "+221 77 000 00 00",
    // },
    {
      id: 2,
      title: "Besoin d'une assistance en direct ?",
      action: "mailto:xyz@abc.com",
      text: "info@memorialbateaulejoola.sn",
    },
  ];
  return (
    <>
      {contactContent.map((item) => (
        <div className="mb-20" key={item.id}>
          <div className={"text-14"}>{item.title}</div>
          <a href={item.action} className="text-18 fw-500 text-dark-1 mt-5">
            {item.text}
          </a>
        </div>
      ))}
    </>
  );
};

export default ContactInfo;
